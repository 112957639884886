/* ==========================================================================
  form-search.js
========================================================================== */

/**
 * jQuery function for the ANEFA anefa-form component (Search ANEFA subsites form)
 */
jQuery(document).ready(function($){

    $('.anefa-form__input').select2({
      minimumInputLength: 3,
      width: 'auto',
      containerCssClass: 'select2-selection--no-arrow',
      placeholder: "Tapez les 3 premières lettres",
      minimumResultsForSearch: 1,
      language: {
        errorLoading: function () {
          return 'Les résultats ne peuvent pas être chargés.';
        },
        inputTooLong: function (args) {
          var overChars = args.input.length - args.maximum;

          return 'Supprimez ' + overChars + ' caractère' +
            ((overChars > 1) ? 's' : '');
        },
        inputTooShort: function (args) {
          return '';
        },
        loadingMore: function () {
          return 'Chargement de résultats supplémentaires…';
        },
        maximumSelected: function (args) {
          return 'Vous pouvez seulement sélectionner ' + args.maximum +
            ' élément' + ((args.maximum > 1) ? 's' : '');
        },
        noResults: function () {
          return 'Aucun résultat trouvé';
        },
        searching: function () {
          return 'Recherche en cours…';
        },
        removeAllItems: function () {
          return 'Supprimer tous les éléments';
        }
      }
  });

  $(".anefa-form").on('submit', function(e) {

    var select = $(this).find('select[name="anefa-recherche"]');

    if ( $.trim( select.val() ) === "" ) {

        e.preventDefault();

        var $message = $('<div id="#error_message" class="alert alert--danger alert--form-result"></div>');
        $message.html( 'Vous devez sélectionner un département.' ).appendTo($(this)).hide().fadeIn();

        setTimeout(function() {
          $('#error_message').fadeOut("slow");
        }, 2000 );

        return false;
    }

  });

});
