/* ==========================================================================
  form-contact.js
========================================================================== */

/**
 * jQuery functions for the form placed in contact-region-page template (simple contact form)
 */

jQuery(document).ready(function($){

  /**
   * AJAX submit contact form
   * on submit
   */
   $(".anefa-email-form").on('submit', function(e) {

     $('.error_message, .success_message').each(function( index ) {
       $(this).fadeOut("normal", function() {
           $(this).remove();
       });
     });

     e.preventDefault();

     var form  = $(this),
         btn   = form.find('button[type="submit"]'),
         datas = $(this).serializeObject();

    datas.ajaxNonce = anefaDatas.nonce;

    console.log(datas);

     $.ajax({
       type: 'POST',
       url: anefaDatas.url,
       data: {
         action :  'anefa_email_form',
         data :    form.serialize(),
         nonce :   anefaDatas.nonce,
       },
       beforeSend: function() {
         btn.attr('disabled', true);
         btn.addClass('running');
       },
       complete: function(){
         btn.attr('disabled', false);
         btn.removeClass('running');
       },
       success:function(res, textStatus, XMLHttpRequest) {
         if( res.success === false ) {

           var index, len;

           for (index = 0, len = res.data.length; index < len; ++index) {
             var $errors = $('<div class="alert alert--danger alert--form-result error_message"></div>');
             $errors.html( res.data[index] ).appendTo(form).hide().fadeIn();
           }

           setTimeout(function() {
             $('.error_message').each(function( index ) {
               $(this).fadeOut("normal", function() {
                   $(this).remove();
               });
             });
           }, 10000 );

         } else {

           // reset form
           form.trigger("reset");
           // reset select2js
           $('#recipient').val('').trigger("change");
           $('#status').val('').trigger("change");

           var $success = $('<div class="alert alert--success alert--form-result success_message"></div>');
           $success.html( res.data ).appendTo(form).hide().fadeIn();

           setTimeout(function() {
             $success.fadeOut("normal", function() {
                 $(this).remove();
             });
           }, 10000 );

         }
       },
       error: function(XMLHttpRequest, textStatus, errorThrown) {
         console.log(errorThrown);
       }
     });

   });

});

(function($,undefined){

  $.fn.serializeObject = function(){
    var obj = {},
        names = {};

    $.each( this.serializeArray(), function(i,o){
      var n = o.name,
        v = o.value;

        if ( n.includes( '[]' ) ) {
          names.n = !names.n ? 1 : names.n+1;
          var indx = names.n - 1;
          n = n.replace( '[]', '[' + indx + ']' );
        }

        obj[n] = obj[n] === undefined ? v
          : $.isArray( obj[n] ) ? obj[n].concat( v )
          : [ obj[n], v ];
    });

    return obj;
  };

})(jQuery);
